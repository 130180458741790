import React from "react";
import linkedinIcon from "../../../assets/Team/mobile/index.png";
import twitterIcon from "../../../assets/Team/mobile/x.png";
import { mobile_profile } from "./Profile";
import "./Team.css";
const TeamMembers = ({ photo, name, title, linkedin, twitter, des }) => (

  <div className="d-flex justify-content-center">
    <div className="card-team">
      <img src={photo} />
      <div className="card-body">
        <div class="row">
          <div class="col-8">
            <div className="cardTitle"> {name} </div>
            <div className="cardText"> {title} </div>
          </div>
          <div class="col-4">
            <span className="float-right">
              {linkedin === "" ? (
                ""
              ) : (
                <a href={linkedin} target="_blank" rel="noreferrer">
                  <img
                    src={linkedinIcon}
                    alt="linkedin icon"
                    width="35px"
                    className="pt-2 mr-2"
                  />
                </a>
              )}
              {twitter === "" ? (
                ""
              ) : (
                <a href={twitter} target="_blank" rel="noreferrer">
                  <img
                    src={twitterIcon}
                    alt="linkedin icon"
                    width="35px"
                    className="pt-2"
                  />
                </a>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>

  </div>
);

const TeamFlipCard = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div >
        <div className="teamHeader-card globalHeaderBgS">
          <div className="container">
            <h1 className="meetteam">
              MEET THE TEAM
            </h1>
          </div>
        </div>
        <div className="container" >
          <div className="row">
            {mobile_profile.map((profile, index) => (
              <div key={index} className="col-lg-4 col-md-4 col-sm-12 my-2">
                <TeamMembers
                  photo={profile.photo}
                  name={profile.name}
                  title={profile.title}
                  linkedin={profile.linkedin}
                  twitter={profile.twitter}
                  des={profile.des}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamFlipCard;