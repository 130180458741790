
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';
import Gnosis from '../../../assets/journaler/NewJournaler/Gnosis.png';
import binance from '../../../assets/journaler/NewJournaler/binance.png';
import coinbase from '../../../assets/journaler/NewJournaler/coinbase.png';
import fireblock from '../../../assets/journaler/NewJournaler/fireblock.png';
import IntegrationAnimation from '../../../assets/journaler/NewJournaler/integration.mov';
import quickbook from '../../../assets/journaler/NewJournaler/quickbook.png';
import xero from '../../../assets/journaler/NewJournaler/xero.png';
import ButtonGroup from '../main/components/ButtonGroup';
import './NewIntegration.css';

const NewIntegration = () => {
    return (
        <div>
            <Helmet>
                <title>AEM Algorithm | Easily Report Your Crypto Into Xero Accounting</title>
                <meta name="Integrations" content="Sync crypto transactions, invoices, bills, tax liabilities & wallet balances directly into your Xero accounting system with AEM Journaler. Sign up now with a free trial." />
            </Helmet>
            <div className='networkHeader newIntegration'>
                <div className='container text-center'>
                    <div className='pt-5'>
                        <span className='headerTitle'>Integrations </span>  <span className='headerTitleWhite'> for powerful <br></br> crypto accounting</span>
                    </div>
                    <div className='subeaderTitle pt-3'>
                        Set up direct connections with your existing accounting systems, exchanges, <br></br>and wallet platforms with Journaler's supported integrations.
                    </div>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='row  align-items-center'>
                    <div className='col-6'>
                        {/* <img className='img-fluid' src={IntegrationAnimation}></img> */}
                        {/* <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item" src={IntegrationAnimation} allowfullscreen></iframe>
                        </div> */}
                        <ReactPlayer
                            url={IntegrationAnimation}
                            controls={true}
                            muted={true}
                            playing={true}
                            width='100%'
                            height='100%'
                        />
                    </div>
                    <div className='col-6'>
                        <div> <span className='seamless'>Seamless</span> <span className='crypto'> crypto accounting experience </span></div>
                        <div className='subtitleIntegrate pt-3'>
                            Connect Journaler with you accounting software and cryptocurrency exchanges.
                        </div>
                    </div>
                </div>
            </div>


            <div className='container pt-5' >
                <div className='row gx-5 align-items-center justify-content-around'>
                    <div className='col-12 col-sm-4'>
                        <div className='seamless'>Accounting software</div>
                        <div className='subtitleIntegrate'>Accounting software</div>
                    </div>
                    <div className='col-5 col-sm-4 colData '>
                        <div>
                            <img className='img-fluid' src={xero}></img>
                        </div>
                        <div className='colTitle'>
                            Xero
                        </div>
                        <div>
                            <a className='linkdetailCol' href='/journaler/xero-integration'>Learn more</a>

                        </div>
                    </div>
                    <div className='col-5 col-sm-4 colData '>
                        <div>
                            <img className='img-fluid' src={quickbook}></img>
                        </div>
                        <div className='colTitle'>
                            QuickBooks
                        </div>

                    </div>
                </div>
            </div>

            <div className='container pt-5' >
                <div className='row gx-5 align-items-center justify-content-around'>
                    <div className='col-12 col-sm-4'>
                        <div className='seamless'>Exchange</div>
                        <div className='subtitleIntegrate'>Import wallet transaction data via exchange platforms</div>
                    </div>
                    <div className='col-5 col-sm-4 colData '>
                        <div>
                            <img className='img-fluid' src={binance}></img>
                        </div>
                        <div className='colTitle'>
                            Binance
                        </div>

                    </div>
                    <div className='col-5 col-sm-4 colData '>
                        <div>
                            <img className='img-fluid' src={coinbase}></img>
                        </div>
                        <div className='colTitle'>
                            Coinbase
                        </div>

                    </div>
                </div>
            </div>
            <div className='container pt-5 pb-5' >
                <div className='row gx-5 align-items-center justify-content-around'>
                    <div className='col-12 col-sm-4'>
                        <div className='seamless'>NFT</div>
                        <div className='subtitleIntegrate'>Integrate with digital asset and wallet platforms</div>
                    </div>
                    <div className='col-5 col-sm-4 colData '>
                        <div>
                            <img className='img-fluid' src={fireblock}></img>
                        </div>
                        <div className='colTitle'>
                            Fireblock
                        </div>

                    </div>
                    <div className='col-5 col-sm-4 colData '>
                        <div>
                            <img className='img-fluid' src={Gnosis}></img>
                        </div>
                        <div className='colTitle'>
                            Gnosis
                        </div>

                    </div>
                </div>
            </div>

            <ButtonGroup fdata={'Set up your '} gradientData={'Journaler integration'} ldata={'now'} />
        </div>
    );

}

export default NewIntegration;