import React from 'react';
// blockchain imgs:
//  --- 1st row
import BitcoinImg from '../../../../../assets/xeroIntegration/supportCrypto/btc-new.svg';
import NemImg from '../../../../../assets/xeroIntegration/supportCrypto/nem-new.svg';
import SymbolImg from '../../../../../assets/xeroIntegration/supportCrypto/NEM2.0.png';
import SymbolGrey from '../../../../../assets/xeroIntegration/supportCrypto/Symbol.svg';
import BtcCashImg from '../../../../../assets/xeroIntegration/supportCrypto/Bitcoin Cash-grey.png';

//  --- 2nd row
import EtheriumImg from '../../../../../assets/xeroIntegration/supportCrypto/eth-new.svg';
import ERCImg from '../../../../../assets/xeroIntegration/supportCrypto/ERC20.svg';
import ERC721Img from '../../../../../assets/xeroIntegration/supportCrypto/ERC721.svg';
import USDTImg from '../../../../../assets/xeroIntegration/supportCrypto/usdt-new.svg';
import XRPImg from '../../../../../assets/xeroIntegration/supportCrypto/xrp-new.svg';
import exchangeWalletsImg from '../../../../../assets/xeroIntegration/supportCrypto/Group 1445.svg';
import blockchainWalletsImg from '../../../../../assets/journaler/blockchianWallets.svg';

const blockchain = {
  blockchainWalletTitle: 'Blockchain Wallets',
  exchangeWalletTitle: 'Exchange Wallets',
  firstPartContents: [
    {
      img: BitcoinImg,
      alt: 'bitcoin icon',
      title: 'Bitcoin',
      active: '1'
    },
    {
      img: EtheriumImg,
      alt: 'etherium icon',
      title: 'Ethereum',
      active: '1'
    },
    {
      img: XRPImg,
      alt: 'xrp icon',
      title: 'XRP',
      active: '1'
    },
    {
      img: NemImg,
      alt: 'Nem icon',
      title: 'NEM - XEM',
      active: '1'
    },
    {
      img: USDTImg,
      alt: 'usdt icon',
      title: 'USDT',
      active: '1'
    },
  ],
  secondPartContents: [
    {
      img: NemImg,
      alt: 'Nem icon',
      title: 'NEM - XEM',
      active: '1'
    },
    {
      img: USDTImg,
      alt: 'usdt icon',
      title: 'USDT',
      active: '1'
    },
  ],
};

const BlockchainList = ({ img, alt }) => (
  <div className='card border-0 cryptoCard m-2 eachCrypto'>
    <img src={img} alt={alt} width='125px' height='125px' />
  </div>
);

const Blockchains = () => {
  return (
    <div className='container pt-3'>
      <div className='row pb-5 text-center'>

        <div className='col-lg-6 col-md-12 justify-content-center'>
          <h2 className='title pb-5 mt-5'> {blockchain.blockchainWalletTitle}</h2>
          <div className='cryptoCard py-3 m-2'>
            <img className='img-blockchain' src={blockchainWalletsImg} alt='blockchainWalletsImg' width='70%' />
          </div>
          <h5 className='pt-2'>See our <a href='/journaler/supported-currencies'><strong>FULL</strong></a> list of supported currencies</h5>
        </div>
        <div className='col-lg-6 col-md-12 justify-content-center'>
          <h2 className='title pb-5 mt-5'> {blockchain.exchangeWalletTitle}</h2>
          <div className='cryptoCard py-3 m-2'>
            <img className='img-blockchain' src={exchangeWalletsImg} alt='exchangeWalletsImg' style={{ width: '45%' }} />
          </div>
          <h5 className='pt-2'>Supports <strong>All</strong> known cryptocurrencies</h5>
        </div>
      </div>
    </div>
  );
};

export default Blockchains;
