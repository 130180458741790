import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import React from 'react';
import check_circle_icon from '../../../../assets/pricing/check.png';
import './PlanView.css';
const PlanView = ({ durationMode, currency }) => {
    const subscriptionCardsInfoMonthly = [
        {
            type: 'Compact',
            price: currency === 'usd' ? '$9' : '$15',
            details: [
                {
                    id: 1,
                    value: '2 Blockchain wallets',
                },
                {
                    id: 2,
                    value: 'Unlimited Sub Wallets',
                },
                {
                    id: 3,
                    value: '100 Transactions',
                },
                {
                    id: 4,
                    value: 'Live & CSV imports',
                },
                {
                    id: 5,
                    value: 'CSV exports',
                },
            ],
        },
        {
            type: 'Starter',
            price: currency === 'usd' ? '$49' : '$79',
            details: [
                {
                    id: 1,
                    value: '5 Blockchain wallet',
                },
                {
                    id: 2,
                    value: 'Unlimited Sub Wallets',
                },
                {
                    id: 3,
                    value: '500 Transactions',
                },
                {
                    id: 6,
                    value: 'Live & CSV imports',
                },
                {
                    id: 4,
                    value: 'Tax and portfolio',
                },
                {
                    id: 5,
                    value: 'Xero and QuickBooks Integration',
                },

            ],
        },
        {
            type: 'Premium',
            price: currency === 'usd' ? '$99' : '$159',
            details: [
                {
                    id: 2,
                    value: '10 Blockchain wallet',
                },
                {
                    id: 1,
                    value: 'Unlimited Sub Wallets',
                },
                {
                    id: 3,
                    value: '5,000 Transactions',
                },
                {
                    id: 4,
                    value: 'Live & CSV imports',
                },
                {
                    id: 5,
                    value: 'Tax and portfolio',
                },
                {
                    id: 6,
                    value: 'Xero and QuickBooks Integration',
                },
            ],
        },
        {
            type: 'Pro',
            price: currency === 'usd' ? '$199' : '$320',
            details: [
                {
                    id: 2,
                    value: '20 Blockchain wallet',
                },
                {
                    id: 1,
                    value: 'Unlimited Sub Wallets',
                },
                {
                    id: 3,
                    value: '10,000 Transactions',
                },
                {
                    id: 4,
                    value: 'Live & CSV imports',
                },
                {
                    id: 5,
                    value: 'Tax and portfolio',
                },
                {
                    id: 6,
                    value: 'Xero and QuickBooks Integration',
                },
            ],
        },
    ];
    const subscriptionCardsInfoYearly = [
        {
            type: 'Compact',
            price: currency === 'usd' ? '$69' : '$109',
            details: [
                {
                    id: 1,
                    value: '2 Blockchain wallets',
                },
                {
                    id: 2,
                    value: 'Unlimited Sub Wallets',
                },
                {
                    id: 3,
                    value: '100 Transactions/ month',
                },
                {
                    id: 4,
                    value: 'Live & CSV imports',
                },
                {
                    id: 5,
                    value: 'CSV exports',
                },
            ],
        },
        {
            type: 'Starter',
            price: currency === 'usd' ? '$389' : '$629',
            details: [
                {
                    id: 1,
                    value: '5 Blockchain wallet',
                },
                {
                    id: 2,
                    value: 'Unlimited Sub Wallets',
                },
                {
                    id: 3,
                    value: '500 Transactions/ month',
                },
                {
                    id: 6,
                    value: 'Live & CSV imports',
                },
                {
                    id: 4,
                    value: 'Tax and portfolio',
                },
                {
                    id: 5,
                    value: 'Xero and QuickBooks Integration',
                },

            ],
        },
        {
            type: 'Premium',
            price: currency === 'usd' ? '$769' : '$1239',
            details: [
                {
                    id: 2,
                    value: '10 Blockchain wallet',
                },
                {
                    id: 1,
                    value: 'Unlimited Sub Wallets',
                },
                {
                    id: 3,
                    value: '5,000 Transactions/ month',
                },
                {
                    id: 4,
                    value: 'Live & CSV imports',
                },
                {
                    id: 5,
                    value: 'Tax and portfolio',
                },
                {
                    id: 6,
                    value: 'Xero and QuickBooks Integration',
                },
            ],
        },
        {
            type: 'Pro',
            price: currency === 'usd' ? '$1539' : '$2479',
            details: [
                {
                    id: 2,
                    value: '20 Blockchain wallet',
                },
                {
                    id: 1,
                    value: 'Unlimited Sub Wallets',
                },
                {
                    id: 3,
                    value: '10,000 Transactions/ month',
                },
                {
                    id: 4,
                    value: 'Live & CSV imports',
                },
                {
                    id: 5,
                    value: 'Tax and portfolio',
                },
                {
                    id: 6,
                    value: 'Xero and QuickBooks Integration',
                },
            ],
        },
    ];
    let data = subscriptionCardsInfoMonthly
    if (durationMode !== 'monthly') {
        data = subscriptionCardsInfoYearly;
    }
    return (
        <div className='row cardContainerPricing'>
            {
                data.map((card, index) => (
                    <div key={index} className='col subscriptionCards'>
                        <div className='card' style={{ border: 'unset' }}>
                            <div class="card-bodygg d-flex flex-column">
                                <div className='cardType'>
                                    {card.type}
                                </div>
                                <div className='cardPriceRow'>
                                    <div className='cardPrice'>
                                        {card.price}
                                    </div>
                                    <div className='cardDuration'>
                                        /{durationMode}
                                    </div>
                                </div>
                                <div className='cardQuote'>
                                    What's included
                                </div>
                                {
                                    card.details.map((detail) => (
                                        <div
                                            key={detail.id}
                                            className='cardDetail'
                                        >
                                            <img
                                                src={check_circle_icon}
                                                alt='check'
                                                className='cardImage'
                                            />
                                            <div className='cardDetailValue'>
                                                {detail.value}
                                            </div>
                                        </div>
                                    ))
                                }


                            </div>
                            <div className="card-buttons-view">
                                <button className='cardButton' onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = 'https://journaler.aem.ac/user/register';
                                }}>
                                    Start now for free
                                </button>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    );
};

export default PlanView;
