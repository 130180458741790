export const firstColLinks = {
  label: 'Follow us',
  content: [
    {
      type: '',
      path: 'https://www.facebook.com/AEMalgorithm/',
      name: 'Facebook',
    },
    {
      type: '',
      path: 'https://twitter.com/AEM_Algorithm',
      name: 'Twitter X',
    },
    {
      type: '',
      path: 'https://t.me/MrJournaler',
      name: 'Telegram',
    },
    {
      type: '',
      path: 'https://www.linkedin.com/company/aem-technologies-pty-ltd',
      name: 'LinkedIn',
    },
    {
      type: '',
      path: 'https://www.youtube.com/channel/UCdAv05DQVYVj1ZIKjallrWg',
      name: 'Youtube',
    },
  ]
};

export const secondColLinks = {
  label: 'About us',
  content: [
    {
      type: 'relative',
      path: '/journaler/main',
      name: 'Journaler',
    },
    {
      type: 'relative',
      path: '/journaler/mrJournaler',
      name: 'Mr. Journaler',
    },
    {
      type: 'relative',
      path: '/aem-plus',
      name: 'AEM+',
    },
    {
      type: '',
      path: 'https://www.aemledger.com/help',
      name: 'Help',
    },
    {
      type: 'relative',
      path: '/contact',
      name: 'Contact us',
    },
  ]
};

export const thirdColLinks = {
  label: 'Resources',
  content: [
    {
      type: 'blankLink',
      path: 'https://www.aemledger.com/help',
      name: 'Help',
    },
    {
      type: 'blankLink',
      path: 'https://www.aemledger.com/blog',
      name: 'Blog',
    },
    {
      type: 'blankLink',
      path: 'https://www.aemledger.com/accounting-advisor',
      name: 'Accounting advisor',
    }
  ]
};
export const fourColLinks = {
  label: 'Contact Us',
  content: [
    {
      type: 'relative',
      path: '/investor',
      name: 'Investors',
    },
    {
      type: 'relative',
      path: '/contact',
      name: 'Contact us',
    },
  ]
};

export const locationMobile = {

  content: [
    {
      type: 'blankLink',
      path: 'https://rmitblockchain.io/',
      name: 'RMIT Blockchain Innovation Hub',
    },
    {
      type: 'blankLink',
      path: 'https://www.stoneandchalk.com.au/',
      name: 'Stone & Chalk',
    },
    {
      type: 'blankLink',
      path: 'https://www.cyberport.hk/en',
      name: 'Cyberport',
    }
  ]
}
export const locationAu = {
  label: 'Location AU',
  content: [
    {
      type: 'blankLink',
      path: 'https://rmitblockchain.io/',
      name: 'RMIT Blockchain Innovation Hub',
    },
    {
      type: 'blankLink',
      path: 'https://www.stoneandchalk.com.au/',
      name: 'Stone & Chalk',
    },
  ]
}
export const locationHK = {
  label: 'Location HK',
  content: [
    {
      type: 'blankLink',
      path: 'https://www.cyberport.hk/en',
      name: 'Cyberport',
    }
  ]
}


export const footerCR = 'Copyright 2018 AEM Technologies Pty Ltd - All Rights Reserved';
export const privacyPolicy = 'Privacy Policy';
