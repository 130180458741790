// styles
import TeamPage from '../../../assets/journaler/TeamPage.png';
import { profile } from "./Profile";
import "./Team.css";

const TeamLandingPage = () => {
  // Team information
  // Management & Operations team
  const jakub = profile[0];
  const Matthew = profile[1];
  const Pamela = profile[2];
  const Linh = profile[3];
  // Marketing & Design
  const Leanne = profile[4];
  const Serin = profile[5];
  // Tech Team
  const Fds = profile[6];
  const MatthewReal = profile[12];
  // Advisor
  const Krzysztof = profile[7];
  const Rutger = profile[8];
  const Jonathan = profile[9];
  const Anouk = profile[10];

  return (
    <div className="container-fluid landingPageContainer">
      <div className="container pb-5">
        <h1 className="team-page-title">
          MEET THE TEAM
        </h1>
      </div>
      <div class="bg-image"
        style={{
          backgroundImage: `url(${TeamPage})`,
        }}>

        {/* Management & Operation */}
        <div className="team-circle">
          <p className="team-label">
            Management <br></br>
            &<br></br>
            Operation
          </p>
        </div>
        {/* jakub */}
        <div className="jakub team-member">
          <img src={jakub.photo} alt={jakub.name} width='100%' height='100%' />
          <div className={`${jakub.role && jakub.role !== '' ? 'title-overlay-with-role' : 'title-overlay'}`}>
            <a className="team-name" href={jakub.linkedin !== '' ? jakub.linkedin : jakub.twitter} target="_blank" rel="noreferrer">{jakub.name}</a> <br />
            <span className="team-title"><b>{jakub.title}</b></span>
          </div>
        </div>
        {/* Matthew */}
        <div className="Matthew team-member">
          <img src={Matthew.photo} alt={Matthew.name} width='100%' height='100%' />
          <div className={`${Matthew.role && Matthew.role !== '' ? 'title-overlay-with-role' : 'title-overlay'}`}>
            <a className="team-name" href={Matthew.linkedin !== '' ? Matthew.linkedin : Matthew.twitter} target="_blank" rel="noreferrer">{Matthew.name}</a> <br />
            <span className="team-title"><b>{Matthew.title}</b></span>
          </div>
        </div>
        {/* Pamela */}
        <div className="Pamela team-member">
          <img src={Pamela.photo} alt={Pamela.name} width='100%' height='100%' className='rounded-pic' />
          <div className={`${Pamela.role && Pamela.role !== '' ? 'title-overlay-with-role' : 'title-overlay'}`}>
            <a className="team-name" href={Pamela.linkedin !== '' ? Pamela.linkedin : Pamela.twitter} target="_blank" rel="noreferrer">{Pamela.name}</a> <br />
            <span className="team-title">{Pamela.title}</span>
          </div>
        </div>
        {/* Linh */}
        <div className="Linh team-member">
          <img src={Linh.photo} alt={Linh.name} width='100%' height='100%' className='rounded-pic' />
          <div className={`${Linh.role && Linh.role !== '' ? 'title-overlay-with-role' : 'title-overlay'}`}>
            <a className="team-name" href={Linh.linkedin !== '' ? Linh.linkedin : Linh.twitter} target="_blank" rel="noreferrer">{Linh.name}</a> <br />
            <span className="team-title">{Linh.title}</span>
          </div>
        </div>

        {/* Marketing & Design */}
        {/* Leanne  */}
        <div className="Leanne team-member">
          <img src={Leanne.photo} alt={Leanne.name} width='100%' height='100%' />
          <div className={`${Leanne.role && Leanne.role !== '' ? 'title-overlay-with-role' : 'title-overlay'}`}>
            <a className="team-name" href={Leanne.linkedin !== '' ? Leanne.linkedin : Leanne.twitter} target="_blank" rel="noreferrer">{Leanne.name}</a> <br />
            <span className="team-title">{Leanne.title}</span><br />
            <span className="team-role">{Leanne.role}</span>
          </div>
        </div>
        {/* Serin */}
        <div className="Serin team-member">
          <img src={Serin.photo} alt={Serin.name} width='100%' height='100%' />
          <div className={`${Serin.role && Serin.role !== '' ? 'title-overlay-with-role' : 'title-overlay'}`}>
            <a className="team-name" href={Serin.linkedin !== '' ? Serin.linkedin : Serin.twitter} target="_blank" rel="noreferrer">{Serin.name}</a> <br />
            <span className="team-title">{Serin.title}</span><br />
            <span className="team-role">{Serin.role}</span>
          </div>
        </div>
        {/* Peter */}
        {/* <div className="Peter team-member">
          <img src={Peter.photo} alt={Peter.name} width='200px' height='200px' className='rounded-pic' />
          <div className={`${Peter.role && Peter.role !== '' ? 'title-overlay-with-role' : 'title-overlay'}`}>
            <a className="team-name" href={Peter.linkedin !== '' ? Peter.linkedin : Peter.twitter} target="_blank" rel="noreferrer">{Peter.name}</a> <br />
            <span className="team-title">{Peter.title}</span><br />
            <span className="team-role">{Peter.role}</span>
          </div>
        </div> */}
        {/* Dima */}
        {/* <div className="Dima team-member">
          <img src={Dima.photo} alt={Dima.name} width='100%' height='100%' className='rounded-pic' />
          <div className={`${Dima.role && Dima.role !== '' ? 'title-overlay-with-role' : 'title-overlay'}`}>
            <a className="team-name" href={Dima.linkedin !== '' ? Dima.linkedin : Dima.twitter} target="_blank" rel="noreferrer">{Dima.name}</a> <br />
            <span className="team-title">{Dima.title}</span><br />
            <span className="team-role">{Dima.role}</span>
          </div>
        </div> */}
        <div className="team-circle-marketing">
          <p className="team-label">
            Marketing <br></br>
            &<br></br>
            Design
          </p>
        </div>

        {/* Teach Team */}
        <div className="team-circle-tech">
          <p className="team-label">
            Tech Team
          </p>
        </div>
        {/* FDS SOFT */}
        <div className="FDS team-member">
          <img src={Fds.photo} alt={Fds.name} width='100%' height='100%' />
          <div className={`${Fds.role && Fds.role !== '' ? 'title-overlay-with-role' : 'title-overlay'}`}>
            <a className="team-name" href={Fds.linkedin !== '' ? Fds.linkedin : Fds.twitter} target="_blank" rel="noreferrer">{Fds.name}</a> <br />
            <span className="team-title">{Fds.title}</span>
          </div>
        </div>
        <div className="techTeam team-member">
          <img src={MatthewReal.photo} alt={MatthewReal.name} width='100%' height='100%' />
          <div className={`${MatthewReal.role && MatthewReal.role !== '' ? 'title-overlay-with-role' : 'title-overlay'}`}>
            <a className="team-name" href={MatthewReal.linkedin !== '' ? MatthewReal.linkedin : MatthewReal.twitter} target="_blank" rel="noreferrer">{MatthewReal.name}</a> <br />
            <span className="team-title">{MatthewReal.title}</span>
          </div>
        </div>


        {/* Advisors */}
        {/* Dr. Jinglee  */}
        <div className="Krzysztof team-member">
          <img src={Krzysztof.photo} alt={Krzysztof.name} width='100%' height='100%' />
          <div className={`${Krzysztof.role && Krzysztof.role !== '' ? 'title-overlay-with-role' : 'title-overlay'}`}>
            <a className="team-name" href={Krzysztof.linkedin !== '' ? Krzysztof.linkedin : Krzysztof.twitter} target="_blank" rel="noreferrer">{Krzysztof.name}</a> <br />
            <span className="team-title">{Krzysztof.title}</span>
          </div>
        </div>
        {/* Rutger  */}
        <div className="Rutger team-member">
          <img src={Rutger.photo} alt={Rutger.name} width='100%' height='100%' />
          <div className={`${Rutger.role && Rutger.role !== '' ? 'title-overlay-with-role' : 'title-overlay'}`}>
            <a className="team-name" href={Rutger.linkedin !== '' ? Rutger.linkedin : Rutger.twitter} target="_blank" rel="noreferrer">{Rutger.name}</a> <br />
            <span className="team-title">{Rutger.title}</span><br />
            <span className="team-role">{Rutger.role}</span>
          </div>
        </div>
        {/* Jonathan  */}
        <div className="Jonathan team-member">
          <img src={Jonathan.photo} alt={Jonathan.name} width='100%' height='100%' />
          <div className={`${Jonathan.role && Jonathan.role !== '' ? 'title-overlay-with-role' : 'title-overlay'}`}>
            <a className="team-name" href={Jonathan.linkedin !== '' ? Jonathan.linkedin : Jonathan.twitter} target="_blank" rel="noreferrer">{Jonathan.name}</a> <br />
            <span className="team-title">{Jonathan.title}</span><br />
            <span className="team-role">{Jonathan.role}</span>
          </div>
        </div>
        {/* Dan Tong  */}

        {/* John  */}

        {/* Anouk  */}
        <div className="Anouk team-member">
          <img src={Anouk.photo} alt={Anouk.name} width='100%' height='100%' />
          <div className={`${Anouk.role && Anouk.role !== '' ? 'title-overlay-with-role' : 'title-overlay'}`}>
            <a className="team-name" href={Anouk.linkedin !== '' ? Anouk.linkedin : Anouk.twitter} target="_blank" rel="noreferrer">{Anouk.name}</a> <br />
            <span className="team-title">{Anouk.title}</span><br />
            <span className="team-role">{Anouk.role}</span>
          </div>
        </div>
        <div className="team-circle-advisor">
          <p className="team-label">
            Advisors
          </p>
        </div>
      </div>
    </div>
  )
}

export default TeamLandingPage
