const pricingPlan = [
    {
        title: 'COMPACT',
        mAudPrice: '15',
        yAudPrice: '109',
        mUsdPrice: '9',
        yUsdPrice: '69',

    },
    {
        title: 'STARTER',
        mAudPrice: '79',
        yAudPrice: '629',
        mUsdPrice: '49',
        yUsdPrice: '389',
        footer: 'GO PRO',
    },
    {
        title: 'PREMIUM',
        mAudPrice: '159',
        yAudPrice: '1239',
        mUsdPrice: '99',
        yUsdPrice: '769',
        footer: 'Try Premium for free',
        subWallet: 'Unlimited'
    },

    {
        title: 'PRO',
        mAudPrice: '320',
        yAudPrice: '2479',
        mUsdPrice: '199',
        yUsdPrice: '1539',
        footer: 'GO PRO',
        subWallet: 'Unlimited'
    }
]


export default pricingPlan;