import Anouk from "../../../assets/Team/Anouk.png";
import Donna from "../../../assets/Team/Donna.png";
import Hailee from "../../../assets/Team/Hailee.png";
import Jakub from "../../../assets/Team/Jakub.png";
import Jinglee from "../../../assets/Team/Jinglee.png";
import KP from "../../../assets/Team/KP.png";
import LeanneStaAna from "../../../assets/Team/Leanne.png";
import Linh from "../../../assets/Team/Linh.jpg";
import Matthew from "../../../assets/Team/Matthew Photo.png";
import Rutger from "../../../assets/Team/Rutger.png";
import Serin from "../../../assets/Team/Serin.png";
import fds from "../../../assets/Team/fds.png";

import Jinglee_mobile from "../../../assets/Team/mobile/Jinglee.png";
import Leanne_mobile from "../../../assets/Team/mobile/Leanne.png";
import Matthew_mobile from "../../../assets/Team/mobile/Matthew_mobile.png";
import Rutger_mobile from "../../../assets/Team/mobile/Rutger.png";
import anouk_mobile from "../../../assets/Team/mobile/anouk.png";
import donna_mobile from "../../../assets/Team/mobile/donna.png";
import fds_mobile from "../../../assets/Team/mobile/fds.png";
import hailee_mobile from "../../../assets/Team/mobile/hailee.png";
import Jakub_mobile from "../../../assets/Team/mobile/jakub.png";
import kp_mobile from "../../../assets/Team/mobile/kp.png";
import linh_mobile from "../../../assets/Team/mobile/linh.png";
import serin_mobile from "../../../assets/Team/mobile/serin.png";


const profile = [
  {
    photo: Jakub,
    name: "Jakub Sawczuk",
    title: "Founder / CEO",
    linkedin: "",
    twitter: "https://twitter.com/jakub_l_sawczuk",
    des: "Jakub is a certified accountant with over a decade of experience in Financial Management and Systems Integration. Before that Jakub has spent another decade as a Product Designer Engineer working for companies such as GE across Europe and the US and completing high end technical and corporate courses in collaboration with Georgia Tech in the US. Jakub holds undergraduate degrees acquired at universities in Australia (Swinburne, Melbourne), Holland (Hanzehogeschool, Groningen) and Spain (CPS, Zaragoza), as well as postgraduate certification in Accounting (Swinburne). Jakub holds provisional membership of the Chartered Accountants (Deakin), membership of the Institute of Certified Bookkeepers and a membership of Tax Practitioners Board of Australia as a BAS Agent. Jakub’s role is to manage the team and the operations, but at the same time to lead and verify lot of the conceptualisation of the systems developed by AEM.",
  },
  {
    photo: Donna,
    name: "Donna McConnell",
    title: "Head of Community",
    linkedin: "https://www.linkedin.com/in/iswys-donnamcconnell",
    twitter: "",
    des: "",
  },
  {
    photo: Hailee,
    name: "Hailee",
    title: "Head of PR",
    linkedin: "",
    twitter: "https://twitter.com/jhueee_art",
  },
  {
    photo: Linh,
    name: "Linh Nguyen",
    title: "Financial Controller",
    linkedin: "https://www.linkedin.com/in/linhhanguyen/",
    twitter: "",
  },
  {
    photo: LeanneStaAna,
    name: "Leanne Sta Ana",
    title: "Head of Marketing",
    linkedin: "https://www.linkedin.com/in/leanne-sta-ana/",
    role: "Marketing",
    twitter: "",
    des: "Leanne is a Campaign Strategist at AEM Algorithm, focusing on the company blog and social media campaigns. With a strong background in copywriting and content creation, Leanne specialises in combining creativity and innovative marketing with strategic research and methodical thinking. She implements a unique human-centred approach to marketing by helping B2B and B2C brands connect authentically and empathetically with their desired audiences. Leanne graduated with High Distinction in the Bachelor of Communications (Professional Communication) at RMIT University with a specialisation in Media.",
  },
  {
    photo: Serin,
    name: "Serin Park",
    title: "Designer",
    role: "UI/UX",
    linkedin: "https://www.linkedin.com/in/serin-park/?originalSubdomain=au",
    twitter: "",
    des: "Serin Park is a multi-disciplinary designer with a passion for User Experience and Interface(UX/UI) design. Her background is in Communication Design and Visual art with 2 years experience in a diverse range of industries. She uses visual communications as a way of exploring and understanding human experience.",
  },
  {
    photo: fds,
    name: "FDS Soft",
    title: "Solution Developer",
    linkedin: "https://www.linkedin.com/company/fdssoft",
    twitter: "",
    des: `One of the leading companies in South East Asia for blockchain and software full services consulting,
    FDSSoft works closely with AEM Algorithm to build the back-end infrastructure of AEM Journaler and AEM+.
    The FDS team is responsible for refining our mobile and web applications to a high level industry standard,
    specialising in startup solution consulting and support. With the ongoing assistance of FDS,
    AEM has successfully delivered innovative crypto accounting solutions for B2B and Web3.

    FDS also provides cutting-edge software consulting services to clients worldwide, including in Europe, US, Australia and South East Asia. Their client portfolio features Roche, Hyosung, NEM, dHealth, Symbol and more. They've built an outstanding legacy in  Business Analysis, UI/UX design, Data science, Blockchain, AI to mobile, cloud, web app development and deployment.
     `,
  },
  {
    photo: KP,
    name: "KP",
    title: "Mentor",
    role: "",
    linkedin: "",
    twitter: "https://twitter.com/chrisek?s=20",
  },
  {
    photo: Jinglee,
    name: "Dr. Jinglee",
    title: "Mentor",
    linkedin: "",
    twitter: "https://x.com/ordijingle?s=11&t=wV4EgJCMT8cR-QIFK8UxLw",
    des: "",
  },
  {
    photo: Rutger,
    name: "Rutger Uittenbogaard",
    title: "Advisor",
    linkedin: "https://www.linkedin.com/in/rutger-uittenbogaard/",
    twitter: "",
    role: "Marketing",
    des: "Rutger has over a decade of experience in online marketing. As a Digital Marketer, SEO copywriter and Creative Content Editor with experience in both the B2B and B2C sector, he has created successful ad campaigns, and written compelling and SEO strong content in order to generate more traffic and a higher conversion rate. He has covered a wide range of topics such as astronomy, video games, the best sights to see in European capitals, and even avid hikers found their perfect holiday location thanks to a successful ad campaign for a British booking company. Back in 2017, he was part of an ambitious cryptocurrency news outlet, which gave birth to his interest in everything blockchain related.",
  },
  {
    photo: Anouk,
    name: "Anouk Pinchetti",
    role: "Blockchain Technology",
    title: "Advisor",
    linkedin: "https://www.linkedin.com/in/anouk-pinchetti-076966/",
    twitter: "https://twitter.com/anouk_pinchetti",
    des: "I have a broad range of corporate commercial, financial services, funds management and banking and finance experience with a particular emphasis on financial services and funds management. I have taken a keen interest in Blockchain and crypto currencies having advised clients on the establishment of crypto currency funds, establishing crypto currency exchanges, initial coin offerings (ICOs), operating ICO marketing platforms and operating crypto wallets. I am also a mentor for the RMIT Blockchain Strategy Course. I also have a keen interest in startups and fintech companies. I have acted for a wide range of financial services providers including retail and wholesale fund managers, investment advisers, financial planners, stock brokers, IDPS operators and managed discretionary account providers. I have advised financial service providers on all aspects of managed investments schemes, AFSL and ACL licensing, disclosure requirements and ongoing compliance with regulatory requirements.",
  },
  {
    photo: fds,
    name: "FDS Soft",
    title: "Solution Developer",
    linkedin: "https://www.linkedin.com/company/fdssoft",
    twitter: "",
    des: `One of the leading companies in South East Asia for blockchain and software full services consulting,
    FDSSoft works closely with AEM Algorithm to build the back-end infrastructure of AEM Journaler and AEM+.
    The FDS team is responsible for refining our mobile and web applications to a high level industry standard,
    specialising in startup solution consulting and support. With the ongoing assistance of FDS,
    AEM has successfully delivered innovative crypto accounting solutions for B2B and Web3.

    FDS also provides cutting-edge software consulting services to clients worldwide, including in Europe, US, Australia and South East Asia. Their client portfolio features Roche, Hyosung, NEM, dHealth, Symbol and more. They've built an outstanding legacy in  Business Analysis, UI/UX design, Data science, Blockchain, AI to mobile, cloud, web app development and deployment.
     `,
  },
  {
    photo: Matthew,
    name: "Matthew VanMullem",
    title: "Technical",
    linkedin: "https://www.linkedin.com/in/mvanmullem/",
    twitter: "",
    des: "Matthew VanMullem is an ICT systems architect with 15 years of technical expertise working with technologies from VMware, Microsoft, Apple, Cisco, and HPE. His broad base of expertise has lead to work with a diverse set of past projects, enabling him to act as a trusted consultant with a holistic view of client operations. His current focus is providing business automation solutions",
  },
  // {
  //   photo: Shin,
  //   name: 'Shin Tatt Wong',
  //   title: 'Team Lead',
  //   linkedin: 'https://www.linkedin.com/in/shintatt/',
  //   twitter: '',
  //   des: 'Shin Tatt is a full stack developer with development experiences in Web & Enterprise applications, and 3D Games. He currently provides help to projects on understanding the concept of decentralisation and whether it will be useful in the problems their projects are aiming to solve.'
  // },
  // {
  //   photo: Ferran,
  //   name: 'Ferran Prat',
  //   title: 'Project Manager',
  //   linkedin: 'https://www.linkedin.com/in/ferran-prat-tio/',
  //   twitter: 'https://twitter.com/ferranprat12',
  //   des: 'Ferran is a computer engineer specialized in Blockchain technology. In his portfolio, the participation and development of several open source tools for the blockchain of NEM and Bitcoin stands out. He has also been a speaker at numerous technological events in the public and private sectors and author of several blockchain courses. He also leads some of the most important innovation and blockchain communities in Barcelona. In addition, he has been involved in hackathons as a participant and as a mentor, obtaining the first prize of the UPC in a blockchain project for NGOs. He is the CEO of Peersyst.'
  // },
  // {
  //   photo: Adria,
  //   name: 'Adrià Carrera',
  //   title: 'Senior Software Engineer',
  //   linkedin: 'https://www.linkedin.com/in/adri%C3%A0-carrera-mas-612a6396/',
  //   twitter: '',
  //   des: 'Adrià is a senior software engineer specialized in Blockchain technology with experience leading different IT projects. With innovation as his key value, he has in depth experience in various blockchains such as Bitcoin, Ethereum, NEM and Symbol. He has also been involved in artificial intelligence projects for the retail sector. He is the CTO of Peersyst.'
  // },
  // {
  //   photo: Russell,
  //   name: 'Russell Black',
  //   title: 'Senior Software Engineer',
  //   linkedin: '',
  //   twitter: '',
  //   des: 'Russell Black is a senior software engineer with over 15 years of professional programming experience. His work has included high-performance realtime applications in C++ and assembly language, mobile apps, console game development, desktop and database apps, embedded systems, python, javascript and various web technologies. His main focus is well-structured, maintainable code.'
  // },
  // {
  //   photo: Rochelle,
  //   name: 'Rochelle Weeratunge',
  //   title: 'Project Lead',
  //   linkedin: 'https://www.linkedin.com/in/rochelle-weeratunge/',
  //   twitter: '',
  //   des: 'Rochelle Weerautnge is an information systems major with a particular interest in accessible tech and UX. She has experience in both the front and backend of website creation management. She brings her Agile ways of working and Design Thinking methodology experience to our team.'
  // },
  // {
  //   photo: Leo,
  //   name: 'Leonardo Martins',
  //   title: 'Software Engineer',
  //   linkedin: 'https://www.linkedin.com/in/leonardorbm/',
  //   twitter: '',
  //   des: 'For the past 3 years Leo has been developing applications such as e-commerce websites and backend applications to support Australia’s largest financial institutions. His experience spans from AWS deployment through to programming languages and frameworks such as Java, NodeJS, Typscript and React for frontend. Leonardo has a passion for design combined with a Mining Engineering degree through Universidade Federal de Minas Gerais (Brazil) with over 10 years experience in design, optimization and operation of various minerals processing facilities he has honed his skills in delivering technical applications across varied industries.'
  // },
  // {
  //   photo: Artur,
  //   name: 'Artur Gromek',
  //   title: 'Software Engineer',
  //   linkedin: 'https://www.linkedin.com/in/artur-gromek/',
  //   twitter: '',
  //   des: 'Artur Gromek is a Software Engineer with experience across the full stack of web development as well as mobile application development. He is proficient in back-end technologies including Node.js and ASP.Net Core, and front-end technologies such as React and Blazor. He holds an undergraduate degree in Software Engineering acquired at RMIT University in Melbourne. His focus for clean, maintanable and structured code is a testament to his passion for programming.'
  // },
  // {
  //   photo: Dee,
  //   name: 'Zidi Hu',
  //   title: 'Front End Developer',
  //   linkedin: 'https://www.linkedin.com/in/zidi-hu/',
  //   twitter: '',
  //   des: 'Zidi is a software developer with some experience in graphic design. She is familiar with front-end development languages such as React, HTML, JavaScript, Bootstrap, and is also proficient in design software such as PhotoShop and Illustrator. With a master degree in IT and a bachelor degree in art, she is passionate about exploring the fusion of design and programming, and remains curious and passionate about new things.'
  // },
  // {
  //   photo: Bella,
  //   name: 'Bella Sendin',
  //   title: 'Marketing Strategist',
  //   linkedin: '',
  //   twitter: '',
  //   des: 'Marketing strategist at AEM. Bella is in her final year of the Bachelor of Communications (Professional) at RMIT University. Bella showcases her abilities through a specialised interest in marketing strategy and public relations and provides a unique collaborative style of working.'
  // },
  // {
  //   photo: Jasmine,
  //   name: 'Jasmine Iluffi',
  //   title: 'Marketing Lead',
  //   linkedin: '',
  //   twitter: '',
  //   des: 'Business Analyst at AEM Algorithm. Penultimate year of Bachelor of Business (Professional Accounting) at RMIT University. Particular interest in financial report reconciliation and projection.'
  // },
  // {
  //   photo: Chaofan,
  //   name: 'Chaofan Wu',
  //   title: 'Front End Developer',
  //   linkedin: '',
  //   twitter: '',
  //   des: 'Chaofan Wu is a web developer and currently work as a software developer for AEM Algorithm. She mainly focuses on the front-end development to ensure the web application meet the user experience and design requirements. And work in teams alongside Back end developers to ensure the development is consistent. '
  // },
  // {
  //   photo: Anthony,
  //   name: 'Anthony Law',
  //   title: 'Blockchain Solution Advisor',
  //   linkedin: 'https://www.linkedin.com/in/anthony-law-yong-chuan-53465b5a/',
  //   twitter: '',
  //   des: 'Anthony Law is a full stack developer with over 6 years of professional programming experience and the main language is on Javascript. In additional involved in blockchain development over a year, and doing DevOps job for deploy private chain solution especially in NEM blockchain.  Currently a Trainer and Consultant for NEM technical'
  // },
  // {
  //   photo: Jason,
  //   name: 'Jason Lee',
  //   title: 'Consultant',
  //   linkedin: 'https://www.linkedin.com/in/jasonleecj/',
  //   twitter: 'https://twitter.com/jasonleecj',
  //   des: 'Jason Lee has been named in Forbes 30 Under 30 Asia List where he has been recognised as one of the young disruptors, innovators and entrepreneurs reshaping industries today across Asia-Pacific Region. He is based in Melbourne and holds the role of the Expansion Director for Australia and New Zealand for the NEM.io Foundation, a not for profit organisation promoting the benefits and use of the NEM blockchain technology platform. His core function is to drive partnership with startups, academia, industry bodies and governments.'
  // },
  // {
  //   photo: Cheryl,
  //   name: 'Cheryl Zhao',
  //   title: 'Software Engineer',
  //   linkedin: '',
  //   twitter: '',
  //   des: 'Cheryl is a Software Engineer with experience across the full stack of web development. She is proficient in back-end technologies such as Node.js and the knowledge of React for front-end technologies. At AEM, Cheryl works on integrating QuickBooks. With a Postgraduate Degree of Professional Accounting at Monash niversity and Postgraduate Degree of IT acquired at RMIT University, she works with the team to develop a better AEM platform based on her greater familiarity with accounting software.'
  // },

  // {
  //   photo: Rutger,
  //   name: "Rutger Uittenbogaard",
  //   title: "Marketing Advisor",
  //   linkedin: "",
  //   twitter: "",
  //   des: "Rutger has over a decade of experience in online marketing. As a Digital Marketer, SEO copywriter and Creative Content Editor with experience in both the B2B and B2C sector, he has created successful ad campaigns, and written compelling and SEO strong content in order to generate more traffic and a higher conversion rate. He has covered a wide range of topics such as astronomy, video games, the best sights to see in European capitals, and even avid hikers found their perfect holiday location thanks to a successful ad campaign for a British booking company. Back in 2017, he was part of an ambitious cryptocurrency news outlet, which gave birth to his interest in everything blockchain related.",
  // },
  // {
  //   photo: Johnson,
  //   name: 'Johnson Xu',
  //   title: 'Network Consultant',
  //   linkedin: 'https://www.linkedin.com/in/wenjiex/',
  //   twitter: '',
  //   des: 'Johnson is a digital analyst who currently works at a large business advisory & consulting enterprise. He has also worked at a top tier multinational cryptocurrency exchange and a fortune 500 management consulting company. Before his journey with blockchain, he had a background in finance (Postgraduate) and computer science (Undergraduate). He has specialised knowledge about cryptocurrency mining, market analysis and business strategies.'
  // },
  // {
  //   photo: Thilon,
  //   name: "Thilon (Hongbin) Ma",
  //   title: "Blockchain Technical Advisor",
  //   linkedin: "",
  //   twitter: "https://twitter.com/thilon",
  //   des: "Founder of the DATAMAIN blockchain BAAS platform, Media columnist, co-founder of NEMChina. More than ten years of C++, big data, blockchain technology development experience. Thilon is an IT expert with hands-on management experience. In 2005, worked on the architecture and research for eMule, a peer-to-peer file sharing software on a well-known sharing website, VeryCD. In 2010, I was responsible for architectures and researches of the network manager in Green Packet (a Malaysian listed company) Shanghai office. In 2014, was the director of the Internet finance department at a financial management company, Hengyu Finance. Daily duties include the development of a P2P platform, as well as R&D team-leading. Since 2016, big data analysis has been done through AlibabaClound’s Max computer/e-mr. Years of industry experience makes him an expert in big data analysis, Java development, and C. /C++ development and architecture.",
  // },
  // {
  //   photo: Heath,
  //   name: 'Heath Mitchell',
  //   title: 'Business Advisor',
  //   linkedin: 'https://www.linkedin.com/in/heathmitchellboydsquires/',
  //   twitter: '',
  //   des: 'Heath who currently holds a Bachelor of Laws (LLB)/Bachelor of Finance (First class honours) works as a law graduate at MinterEllison. Having been interested in blockchain technology for many years, he has devoted the next stage of his career to combining his technical areas of interest to help grow blockchain adoption and investment. He holds specialist knowledge about the blockchain/cryptocurrency regulatory environment and relevant legal considerations throughout a startup lifecycle.'
  // },
  // {
  //   photo: Tony,
  //   name: 'Tony Sanak',
  //   title: 'Blockchain Mentor',
  //   linkedin: '',
  //   twitter: '',
  //   des: 'Full time blockchain developer and content creator. Explores cryptocurrency project one at a time and documenting his journey on his Youtube channel. Blockchain AML expert and Lightning Network adoption influencer.'
  // },
];

const mobile_profile = [
  {
    photo: Jakub_mobile,
    name: "Jakub Sawczuk",
    title: "Founder / CEO",
    linkedin: "",
    twitter: "https://twitter.com/jakub_l_sawczuk",
    des: "Jakub is a certified accountant with over a decade of experience in Financial Management and Systems Integration. Before that Jakub has spent another decade as a Product Designer Engineer working for companies such as GE across Europe and the US and completing high end technical and corporate courses in collaboration with Georgia Tech in the US. Jakub holds undergraduate degrees acquired at universities in Australia (Swinburne, Melbourne), Holland (Hanzehogeschool, Groningen) and Spain (CPS, Zaragoza), as well as postgraduate certification in Accounting (Swinburne). Jakub holds provisional membership of the Chartered Accountants (Deakin), membership of the Institute of Certified Bookkeepers and a membership of Tax Practitioners Board of Australia as a BAS Agent. Jakub’s role is to manage the team and the operations, but at the same time to lead and verify lot of the conceptualisation of the systems developed by AEM.",
  },
  {
    photo: donna_mobile,
    name: "Donna McConnell",
    title: "Head of Community",
    linkedin: "https://www.linkedin.com/in/iswys-donnamcconnell",
    twitter: "",
    des: "",
  },
  {
    photo: hailee_mobile,
    name: "Hailee",
    title: "Head of PR",
    linkedin: "",
    twitter: "https://twitter.com/jhueee_art",
  },
  {
    photo: linh_mobile,
    name: "Linh Nguyen",
    title: "Financial Controller",
    linkedin: "https://www.linkedin.com/in/linhhanguyen/",
    twitter: "",
  },
  {
    photo: Leanne_mobile,
    name: "Leanne Sta Ana",
    title: "Head of Marketing",
    linkedin: "https://www.linkedin.com/in/leanne-sta-ana/",
    role: "Marketing",
    twitter: "",
    des: "Leanne is a Campaign Strategist at AEM Algorithm, focusing on the company blog and social media campaigns. With a strong background in copywriting and content creation, Leanne specialises in combining creativity and innovative marketing with strategic research and methodical thinking. She implements a unique human-centred approach to marketing by helping B2B and B2C brands connect authentically and empathetically with their desired audiences. Leanne graduated with High Distinction in the Bachelor of Communications (Professional Communication) at RMIT University with a specialisation in Media.",
  },
  {
    photo: serin_mobile,
    name: "Serin Park",
    title: "Designer",
    role: "UI/UX",
    linkedin: "https://www.linkedin.com/in/serin-park/?originalSubdomain=au",
    twitter: "",
    des: "Serin Park is a multi-disciplinary designer with a passion for User Experience and Interface(UX/UI) design. Her background is in Communication Design and Visual art with 2 years experience in a diverse range of industries. She uses visual communications as a way of exploring and understanding human experience.",
  },
  {
    photo: kp_mobile,
    name: "KP",
    title: "Mentor",
    role: "",
    linkedin: "",
    twitter: "https://twitter.com/chrisek?s=20",
  },
  {
    photo: Jinglee_mobile,
    name: "Dr. Jinglee",
    title: "Mentor",
    linkedin: "",
    twitter: "https://x.com/ordijingle?s=11&t=wV4EgJCMT8cR-QIFK8UxLw",
    des: "",
  },
  {
    photo: Rutger_mobile,
    name: "Rutger Uittenbogaard",
    title: "Advisor",
    linkedin: "https://www.linkedin.com/in/rutger-uittenbogaard/",
    twitter: "",
    role: "Marketing",
    des: "Rutger has over a decade of experience in online marketing. As a Digital Marketer, SEO copywriter and Creative Content Editor with experience in both the B2B and B2C sector, he has created successful ad campaigns, and written compelling and SEO strong content in order to generate more traffic and a higher conversion rate. He has covered a wide range of topics such as astronomy, video games, the best sights to see in European capitals, and even avid hikers found their perfect holiday location thanks to a successful ad campaign for a British booking company. Back in 2017, he was part of an ambitious cryptocurrency news outlet, which gave birth to his interest in everything blockchain related.",
  },
  {
    photo: anouk_mobile,
    name: "Anouk Pinchetti",
    role: "Blockchain Technology",
    title: "Advisor",
    linkedin: "https://www.linkedin.com/in/anouk-pinchetti-076966/",
    twitter: "",
    des: "I have a broad range of corporate commercial, financial services, funds management and banking and finance experience with a particular emphasis on financial services and funds management. I have taken a keen interest in Blockchain and crypto currencies having advised clients on the establishment of crypto currency funds, establishing crypto currency exchanges, initial coin offerings (ICOs), operating ICO marketing platforms and operating crypto wallets. I am also a mentor for the RMIT Blockchain Strategy Course. I also have a keen interest in startups and fintech companies. I have acted for a wide range of financial services providers including retail and wholesale fund managers, investment advisers, financial planners, stock brokers, IDPS operators and managed discretionary account providers. I have advised financial service providers on all aspects of managed investments schemes, AFSL and ACL licensing, disclosure requirements and ongoing compliance with regulatory requirements.",
  },
  {
    photo: fds_mobile,
    name: "FDS Soft",
    title: "Solution Developer",
    linkedin: "https://www.linkedin.com/company/fdssoft",
    twitter: "",
    des: `One of the leading companies in South East Asia for blockchain and software full services consulting,
    FDSSoft works closely with AEM Algorithm to build the back-end infrastructure of AEM Journaler and AEM+.
    The FDS team is responsible for refining our mobile and web applications to a high level industry standard,
    specialising in startup solution consulting and support. With the ongoing assistance of FDS,
    AEM has successfully delivered innovative crypto accounting solutions for B2B and Web3.

    FDS also provides cutting-edge software consulting services to clients worldwide, including in Europe, US, Australia and South East Asia. Their client portfolio features Roche, Hyosung, NEM, dHealth, Symbol and more. They've built an outstanding legacy in  Business Analysis, UI/UX design, Data science, Blockchain, AI to mobile, cloud, web app development and deployment.
     `,
  },
  {
    photo: Matthew_mobile,
    name: "Matthew Van Mullem",
    title: "Infrastructure Architect",
    linkedin: "https://www.linkedin.com/in/mvanmullem/",
    twitter: "",
    des: "Matthew VanMullem is an ICT systems architect with 15 years of technical expertise working with technologies from VMware, Microsoft, Apple, Cisco, and HPE. His broad base of expertise has lead to work with a diverse set of past projects, enabling him to act as a trusted consultant with a holistic view of client operations. His current focus is providing business automation solutions",
  },
]
export { mobile_profile, profile };

