import React, { Component } from 'react';
import './ContactUs.css';
import JoinNewsletter from './components/JoinNewsletter'
import ContactUsForm from './components/ContactUsForm'
import SendEmailSection from './components/SendEmailSection'

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      name: '',
      subject: '',
      message: '',
      showAlert: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log('Receive data:', this.state);
    this.setState({
      email: '',
      name: '',
      subject: '',
      message: '',
      showAlert: true,
    });
  }

  render() {
    return (
      <>
        <div className='contactUsHeadernew'>
          <div className='containerHeader'>
            <div className='container'>
              <h1 className='titleContact'>Contact us</h1>
              <h5 className='subContact'>Questions, bug reports, feedback</h5>
              <h5 className='subContact'>We’re here for all of it.</h5>
            </div>

          </div>

          <ContactUsForm />

        </div>

        <JoinNewsletter />

      </>
    );
  }
}

export default ContactUs;
