import React, { useState } from 'react';
import '../Main.css';

import blockChain from '../../../../assets/journaler/main/Addandview.png';
import import_wallet from '../../../../assets/journaler/main/import_wallet.png';
import connect from '../../../../assets/journaler/main/connect.png';
import import_and_export from '../../../../assets/journaler/main/import_and_export.png';
import cgt from '../../../../assets/journaler/main/cgt.png';

import check_circle_icon from '../../../../assets/pricing/check.png';


const contents = {
  featureOneContent: [
    'Sync directly from the blockchain', 'CSV Exports with FIAT conversion', 'View transactions and wallet balances'
  ],
  featureTwoContent: [
    'Supporting all known currencies, 20,000+',
    'Easy to follow CSV Import template provided',
    'Import wallet transaction data via our integrations'
  ],
  featureThreeContentOne: [
    'Editable tax rate', 'Predefined financial periods', 'Set by country of origin'
  ],
  featureThreeContentTwo: [
    'FIFO, LIFO, HIFO, ACB'
  ],
  featureFourContentOne: [
    'Convert transaction data into $ value', 'Capture transaction descriptions and wallet addresses', 'Export into accounting systems'
  ],
  featureFourContentTwo: [
    'Blockchain wallet data sync', 'Spreadsheets import', 'Custom spreadsheets import', 'Set up Coinbase & Binance integration'
  ],
  featureFiveContentOne: [
    'Set up Xero & QuickBooks integration',
    'Track crypto transactions in crypto and FIAT',
    'Bank feeds: set up automated transaction feed (set as open or reconciled)',
    'Invoice and bill exports as draft, open or settled',
    'Journals (CGT) calculate tax liabilities',
    'Journals (Wallet Balance) report holdings',

  ],
  featureFiveContentTwo: [
    'Bill Exports as draft, open or settled', 'Journals (CGT) calculate tax liabilities', 'Journals (Wallet Balance) report holdings', 'Set up direct QuickBooks integration'
  ]
}



const FeatureContentList = ({ content }) => (
  <div className='row'>
    <div className='col col-1'>
      <img src={check_circle_icon} alt='tick' />
    </div>
    <div className='contentFeature col'>
      <p>{content}</p>
    </div>
  </div>
)


const OurFeatures = () => {
  const [feature, setFeature] = useState(1)

  return (
    <div className='container pt-5 '>
      <div className='titleOurFeatures text-center pb-4 mt-3'>Our Features</div>
      <div className='d-flex justify-content-between detailOurFeatures'>
        <a
          className={feature === 1 ? "btn selectedFeature" : "btn whiteBorderBottom"}
          onClick={() => setFeature(1)}
        >
          Blockchain wallet
        </a>
        <a
          className={feature === 2 ? "btn selectedFeature" : "btn whiteBorderBottom"}
          onClick={() => setFeature(2)}
        >
          Imported wallet</a>
        <a
          className={feature === 5 ? "btn selectedFeature " : "btn  whiteBorderBottom"}
          onClick={() => setFeature(5)}
        >
          Accounting system
        </a>
        <a
          className={feature === 4 ? "btn selectedFeature" : "btn  whiteBorderBottom"}
          onClick={() => setFeature(4)}
        >
          Imports & export
        </a>
        <a
          className={feature === 3 ? "btn selectedFeature" : "btn  whiteBorderBottom"}
          onClick={() => setFeature(3)}
        >
          CGT tax calculator
        </a>


      </div>
      <div className='bg-light mt-5'>

        {feature === 1 &&
          <div className='row'>
            <div className='col d-flex align-items-center pl-5'>
              <div className='d-flex justify-content-center flex-column'>

                <div className='firstHeader'>Blockchain wallet</div>
                <div className='titleHeader pb-3 pt-1'>
                  <span className='solutionJounalerGradient'>Add & view</span>  your wallets by syncing them directly from the blockchain
                </div>
                {contents.featureOneContent.map((content) => (
                  <FeatureContentList content={content} />
                ))}

              </div>
            </div>
            <div className='col'>
              <img src={blockChain} alt='tick' />
            </div>
          </div>
        }

        {feature === 2 &&
          <div className='row'>
            <div className='col d-flex align-items-center pl-5'>
              <div className='d-flex justify-content-center flex-column'>

                <div className='firstHeader'>Imported wallet</div>
                <div className='titleHeader pb-3 pt-1'>
                  <span className='solutionJounalerGradient'>Manually import</span> all of your transaction data
                </div>
                {contents.featureTwoContent.map((content) => (
                  <FeatureContentList content={content} />
                ))}

              </div>
            </div>
            <div className='col'>
              <img src={import_wallet} alt='tick' />
            </div>
          </div>
        }

        {feature === 3 &&
          <div className='row'>
            <div className='col d-flex align-items-center pl-5'>
              <div className='d-flex justify-content-center flex-column'>

                <div className='firstHeader'>CGT tax calculator</div>
                <div className='titleHeader pb-3 pt-1'>
                  <span className='solutionJounalerGradient'>Calculate CGT tax</span> for every single transaction
                </div>
                <div className='subTitlejournaler pb-3'>Comprehensive customisation abilities</div>
                {contents.featureThreeContentOne.map((content) => (
                  <FeatureContentList content={content} />
                ))}
                <div className='subTitlejournaler pb-3'>Reporting</div>
                {contents.featureThreeContentTwo.map((content) => (
                  <FeatureContentList content={content} />
                ))}

              </div>
            </div>
            <div className='col'>
              <img src={cgt} alt='tick' />
            </div>
          </div>
        }

        {feature === 4 &&
          <div className='row'>
            <div className='col d-flex align-items-center pl-5'>
              <div className='d-flex justify-content-center flex-column'>

                <div className='firstHeader'>Imports & export</div>
                <div className='titleHeader pb-3 pt-1'>
                  <span className='solutionJounalerGradient'>Easy</span> import & export transaction data
                </div>
                <div className='subTitlejournaler pb-3'>Data import</div>
                {contents.featureFourContentTwo.map((content) => (
                  <FeatureContentList content={content} />
                ))}
                <div className='subTitlejournaler pb-3'>Data export</div>
                {contents.featureFourContentOne.map((content) => (
                  <FeatureContentList content={content} />
                ))}

              </div>
            </div>
            <div className='col'>
              <img src={import_and_export} alt='tick' />
            </div>
          </div>
        }

        {feature === 5 &&
          <div className='row'>
            <div className='col d-flex align-items-center pl-5'>
              <div className='d-flex justify-content-center flex-column'>

                <div className='firstHeader'>Accounting system</div>
                <div className='titleHeader pb-3 pt-1'>
                  <span className='solutionJounalerGradient'>Connect</span> to your existing accounting systems
                </div>
                {contents.featureFiveContentOne.map((content) => (
                  <FeatureContentList content={content} />
                ))}

              </div>
            </div>
            <div className='col'>
              <img src={connect} alt='tick' />
            </div>
          </div>
        }
      </div>
    </div>
  )
};
export default OurFeatures;
