// Modules
import React from 'react';
// Ultis
import XeroLogo from '../../../../assets/home/integrateSoftware/xero.png'
import CoinbaseLogo from '../../../../assets/home/integrateSoftware/coinbase.png'
import Fireblocks from '../../../../assets/home/integrateSoftware/fireblock.png'
import QuickbooksLogo from '../../../../assets/home/integrateSoftware/qb.png'
import binance from '../../../../assets/home/integrateSoftware/binance.png';
import Gnosis from '../../../../assets/home/integrateSoftware/gnosis.png';

import '../Main.css'

const logo = [
  {
    img: XeroLogo,
    alt: 'XeroLogo',
    link: 'https://www.xero.com/au/'
  },
  {
    img: binance,
    alt: 'binance',
    link: 'https://www.binance.com/en'
  },
  {
    img: CoinbaseLogo,
    alt: 'CoinbaseLogo',
    link: 'https://www.coinbase.com/au'
  },
  {
    img: Gnosis,
    alt: 'Gnosis',
    link: 'https://www.gnosis.io/'
  },
  {
    img: Fireblocks,
    alt: 'Fireblocks',
    link: 'https://www.fireblocks.com/'
  },
  {
    img: QuickbooksLogo,
    alt: 'QuickbooksLogo',
    link: 'https://quickbooks.intuit.com/au/'
  },
];

const IntegrationCard = ({ img, alt, link }) => (
  <div>
    <a href={link} target="_blank">
      <img src={img} alt={alt} className='logoImage' />
    </a>
  </div>
);

const Integrations = ({ isMobile }) => {
  return (
    <div
      className='row d-flex justify-content-center align-items-center'
      style={{
        fontSize: '21px',
        fontWeight: '500',
        LineHeight: '29.6px'
      }}>
      {
        !isMobile && (
          'Integrated software'
        )
      }
      {logo.map((logo) => (
        <div key={logo.alt} className='integratedPlatforms'>
          <IntegrationCard img={logo.img} alt={logo.alt} link={logo.link} />
        </div>
      ))}
    </div>
  );
};

export default Integrations;
