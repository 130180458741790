import facebook from '../../../assets/contactUs/facebook.svg';
import telegram from '../../../assets/contactUs/telegram.svg';
import twitter from '../../../assets/contactUs/twitter.svg';
import linkedin from '../../../assets/contactUs/linkedin.svg';
import youtube from '../../../assets/contactUs/youtube1.svg';

export const icons = [
  {
    img: facebook,
    alt: 'facebook',
    link: 'https://www.facebook.com/AEMalgorithm/',
  },
  {
    img: twitter,
    alt: 'twitter',
    link: 'https://twitter.com/AEM_Algorithm',
  },
  {
    img: telegram,
    alt: 'telegram',
    link: 'https://t.me/AEMalgorithm',
  },
  {
    img: linkedin,
    alt: 'linkedin',
    link: 'https://www.linkedin.com/company/aem-technologies-pty-ltd',
  },
  {
    img: youtube,
    alt: 'youtube',
    link: 'https://www.youtube.com/channel/UCdAv05DQVYVj1ZIKjallrWg',
  },
];

export const selectorTwoOptions = [
  'Select a topic', 'Using Journaler', 'Integration', 'Manage my account', 'Subscriptions', 'Privacy and security', 'Report an issue'
]

export const selectorThreeOptions = [
  {
    id: 'Select a topic',
    option: ['']
  },
  {
    id: 'Using Journaler',
    option: ['Select a topic', 'Add a wallet', 'Export CSV']
  },
  {
    id: 'Integration',
    option: ['Select a topic', 'Xero integration', 'QuickBooks integration', 'Exchange integration', 'Sync issues', 'Error message', 'Other Integration questions']
  },
  {
    id: 'Integration',
    option: ['Select a topic', 'Xero integration', 'QuickBooks integration', 'Exchange integration', 'Sync issues', 'Error message', 'Other Integration questions']
  },
  {
    id: 'Manage my account',
    option: ['Select a topic', 'Begin Enterprise membership', 'Start/upgrade membership', 'Update account information', 'Change or cancel my membership', 'Forgot my password', 'Other Account and Membership questions']
  },
  {
    id: 'Subscriptions',
    option: ['Select a topic', 'Order status', 'Questions about a charge', 'Late payment', 'Other Payments questions', 'Pause my membership']
  },
  {
    id: 'Privacy and security',
    option: ['Select a topic', 'Privacy and security']
  },
  {
    id: 'Report an issue',
    option: ['Select a topic', 'Report an issue']
  },
]
