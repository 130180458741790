import React from 'react';
import '../Home.css';
import { Link } from 'react-router-dom';

// images:
import ourstory from '../../../assets/home/findOutMore/ourstory.svg';
import theteam from '../../../assets/home/findOutMore/theteam.svg';
import aem from '../../../assets/home/findOutMore/theeam.svg';
import contactUsImg from '../../../assets/home/findOutMore/contactus.svg';
import blog from '../../../assets/home/findOutMore/blog.svg';
import knowledge from '../../../assets/home/findOutMore/knowledge.svg';

// data:
const title = 'FIND OUT MORE';
const contents = [
  {
    src: ourstory,
    alt: 'aem',
    text: 'Our Story',
    path: '/about-us',
  },
  {
    src: theteam,
    alt: 'team',
    text: 'The Team',
    path: '/team',
  },
  {
    src: aem,
    alt: 'networks',
    text: 'AEM Journaler',
    path: '/journaler/main',
  },
  {
    src: blog,
    alt: 'blog',
    text: 'blog',
    path: 'https://www.aemledger.com/blog',
  },
  {
    src: knowledge,
    alt: 'knowledge hub',
    text: 'knowledge',
    path: 'https://www.aemledger.com/help'
  },
  {
    src: contactUsImg,
    alt: 'contact us',
    text: 'Contact Us',
    path: '/contact',
  },
];

const SingleSection = ({ path, src, text, alt }) => (
  <>
    <div className='row justify-content-center'>
      {text === 'blog' || text === 'knowledge' ? (
        <a href={path} rel='noreferrer' target='_blank'>
          <img src={src} alt={alt} width='126px' />
          <p className='card-text text-center navtext'>{text}</p>
        </a>
      ) : (
        <Link to={path}>
          <img src={src} alt={alt} width='126px' />
          <p className='card-text text-center navtext'>{text}</p>
        </Link>
      )}
    </div>
  </>
);

const FindOutMore = () => {
  return (
    <div className='container'>
      <h1 className='text-center p-5 font-weight-bold text-white'>{title}</h1>
      <div className='row pb-5'>
        {contents.map((content) => (
          <div className='col' key={content.text}>
            <SingleSection
              key={content.text}
              src={content.src}
              alt={content.alt}
              text={content.text}
              path={content.path}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FindOutMore;
