import React, { Component } from 'react';
import './About.css';
import aboutUsContents from './data';

// images:
import AboutUsImage from '../../assets/about/AboutusNew.png';
import aboutusxero from '../../assets/about/aboutus-xero.png';
import aemplus from '../../assets/about/aemplus.png';
import fmDiagram from '../../assets/about/diagrams-futuremission.jpg';
import jounaleraem from '../../assets/about/jounaleraem.png';
import roadmap from '../../assets/about/roadmap.png';
import FindOutMore from '../home/components/FindOutMore';

const fullStory = aboutUsContents.ourStory.desComplete.map((content) =>
  <p className='textdesBrief'>{content}</p>
)
const twoColunm = (p) => aboutUsContents.roadMap[p - 1].map((content) =>
  <>
    <div class="row">
      <div class="col-3">
        <p className='contentDetail'> {content[0]}</p>

      </div>
      <div class="col-9">
        <p className='contentDetail'> {content[1]}</p>
      </div>
    </div>
  </>
)

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFullStory: false,
      showDetail: false,
      showcase: 1,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleClickOutside = () => {
    this.setState({ showDetail: false });
    this.setState({ showcase: 0 })
  }
  divDetail(e) {
    return 'divDetail' + e
  }


  onShowDetail(e) {
    if (this.state.showcase === e) {
      this.setState({ showDetail: false });
      this.setState({ showcase: 0 })
    }
    else {
      this.setState({ showDetail: false });
      this.setState({ showcase: 0 })
      this.setState({ showDetail: true });
      this.setState({ showcase: e })
    }


  }

  render() {
    return (
      <div className='fullAboutUs' >
        {/*  ============  Header   */}
        <div className='container aboutUsHeader  text-center'>

          <p className='h1BoldUs'>{aboutUsContents.title}</p>

          <div className='row justify-content-center pt-4'>
            <div style={{ color: 'white' }}>
              <h1 className='aboutUsSub'>{aboutUsContents.subtitle[0]}</h1>
              {/* <h1 className='aboutUsSub'>{aboutUsContents.subtitle[1]}</h1> */}
            </div>
          </div>
          <div className='d-flex justify-content-center'>
            <img className='imageAbout ' src={AboutUsImage} />
          </div>
        </div>

        {/*  Our story section  */}

        <div className='meetteamAbout'>
          <div className='container' style={{ position: 'relative' }}>
            <div className='row'>
              <div className='col-12 col-sm-6 d-flex align-items-center justify-content-center'>
                <img className='img-fluid' src={jounaleraem} />
              </div>
              <div className='col-12 col-sm-6'>
                <div className='title_export-about'>
                  Who We Are
                </div>
                <div className='subtitle_export-about'>
                  A dedicated team on a mission to empower businesses and accountants for the advent of crypto accounting.
                </div>
                <div >
                  <a href='/team' className='btn btnGroup_greenBgBtn_mailchip' style={{ color: '#ffffff' }}>
                    Meet the Team
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='col-sm-6'>
              <div className='title_export-about'>
                Our Story
              </div>
              <div className='subtitle_export-about'>
                It all started during the back-end of the crypto explosion in early 2018.
                AEM founder & CEO Jakub saw a vision into the future, where cryptocurrency transforms
                the way that people transact and manage their assets. One thing led to another,
                and eventually sparked a simple, yet powerful idea: an integrated accounting tool that
                simplifies crypto tracking and reconciliation, AEM Journaler.
              </div>
            </div>
            <div className='col-sm-6 d-flex justify-content-center pb-5'>
              <img src={aboutusxero} style={{ opacity: 0.6 }} />
            </div>
          </div>
        </div>

        <div className='whyaem'>
          <div className='container paddingwhyaem'>
            <div className='row'>
              <div className=' col-sm-6 d-flex justify-content-center'>
                <img className='img-fluid ' src={aemplus} />
              </div>
              <div className='col-sm-6'>
                <div className='title_export-about'>
                  Why AEM?
                </div>
                <div className='subtitle_export-about'>
                  Jakub realised that he wanted the name to reflect what he loved most –
                  his daughter Emanuela, driving his passion and innovation for the business.
                  An anagram of her name, Accounting Enterprise Middleware soon came into existence
                  and the journey to the future of crypto accounting began.
                </div>
              </div>
            </div>

          </div>

        </div>


        <div className='ourMission'>
          <div className='container paddingwhyaem'>
            <div className='title_export-about-mission'>
              Our Mission
            </div>
            <div className='subtitle_export-about-mission'>
              Change has to start somewhere. For us, change starts now.
              We’re on a mission to empower businesses with intuitive, enterprise-grade tools, providing an
              integrated system that is adapted to your every need. We’ve partnered
              up with RMIT, Xero, QuickBooks, and more to help turn our ideas into reality.
            </div>
            <div className='videoYoutube'>
              <iframe width="100%" height="500" src="https://www.youtube.com/embed/eTKDMcrRm-U" title="YouTube video player"
                frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </div>
          </div>
        </div>

        {/* ======== futurn mission seciton */}

        <div className='container paddingwhyaem'>
          <div className='row'>

            <div className='col-12 col-sm-6'>
              <h1 className='sectionTitle futureMissionTitle'>{aboutUsContents.futureMission.title}</h1>
              <div className=' justify-content-left'>
                <p className='textdesBriefFooter'>{aboutUsContents.futureMission.desc[0]}</p>
                <p className='textdesBriefFooter'>{aboutUsContents.futureMission.desc[1]}</p>
              </div>
            </div>

            <div className='col-12 col-sm-6 d-flex align-items-center justify-content-center'>
              <img src={fmDiagram} alt='future mission' width='650px' className='img-fluid' />
            </div>

          </div>
        </div>

        <div className='roadmapNew'>
          <div className='container pt-5 pb-5'>
            <img className='img-fluid ' src={roadmap} />
          </div>
        </div>

        <div className='findOutMoreContainer'>
          <FindOutMore />
        </div>

      </div>
    );
  };
}

export default AboutUs;