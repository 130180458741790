const aboutUsContents = {
  title: 'ABOUT US',
  subtitle: ['We’re redefining the business approach to crypto accounting', 'for the advent of the crypto economy'],
  ourStory: {
    title: 'OUR STORY',
    desBrief: [`It all started during the back-end of the crypto explosion in early 2018. After Jakub was dragged 
    into the blockchain ecosystem by his wife and stepfather - he was hooked. He saw a vision into the future, 
    where cryptocurrency starts a movement that transforms the way that people transact and organise their assets`,
      `Eventually, he found an outlet for this vision. On the very last day of submissions to the 2018 Block engine 
      Program, Jakub recorded a video on his phone which marked the start of AEM as we know it today. He entered with
       one simple idea, a software that facilitated settlements and allowed businesses to pass accounting data from business to business.`,
      `Following the video, he was shortlisted and was asked to join an elite group of businesses and individuals that gave him access to the blockchain community. But first, he needed a like-minded`],
    desComplete: ['It all started during the back-end of the crypto explosion in early 2018. After Jakub was dragged into the blockchain ecosystem by his wife and stepfather - he was hooked. He saw a vision into the future, where cryptocurrency starts a movement that transforms the way that people transact and organise their assets.',
      'Eventually, he found an outlet for this vision. On the very last day of submissions to the 2018 Block engine Program, Jakub recorded a video on his phone which marked the start of AEM as we know it today. He entered with one simple idea, a software that facilitated settlements and allowed businesses to pass accounting data from business to business.',
      'Following the video, he was shortlisted and was asked to join an elite group of businesses and individuals that gave him access to the blockchain community. But first, he needed a like-minded group of individuals to make it a reality.',
      'Jakub knew the perfect man for the job - Matthew Vanmullen, a dynamic infrastructure architect with a strong technical background and a history of working with Jakub. It was here that they began their work as partners in the business. Matthew then hired Russell Black - a senior developer and master of conceptual design, and just like that the dream team was created as they came together with a passion for blockchain.',
      'All Jakub needed now was to come up with a name! After brainstorming, Jakub realized that he wanted the name to reflect what he loved most - his daughter Emanuela, while also showcasing what his business does. Thus, rearranging the first three letters of her name,  he came up with AEM - standing for Accounting Enterprise Middleware.',
      'Through months of hard work and a dramatic expansion of the team, AEM eventually  developed an integrated accounting tool that provides people with an easy way to sort through their crypto transactions. This program was labelled AEM ‘Journaler’.',
      'Being a part of a niche but highly in demand market, Journaler provides a complete set of accounting tools which allows businesses and individuals to fully capitalise on the tracking and reconciling of cryptocurrency! AEM has a holistic approach to modernising transactions and exchange - attending to all needs with simplicity.',
      'Integrating with Xero, NEM and Coinbase, AEM is continuously learning, growing and developing. In the  future AEM hopes to take over the world! Or rather, play a big part on how businesses manage their online transactions.']
  },
  ourMission: {
    title: ['SEAMLESS', 'INTEGRATED', 'SIMPLE'],
    subtitle: 'OUR MISSION',
    des: 'AEM has a mission to empower businesses with seamless enterprise-ready tools, providing an infrastructure that prepares traditional industries for the advent of digital transactions using crypto-currency, by providing a system adapted to their needs.',
    highlight: 'CRYPTO ACCOUNTING DOESN’T NEED TO BE DIFFICULT'
  },
  ourVision: {
    title: 'OUR VISION',
    des: `AEM wants to pioneer a movement that transforms the idea of how both businesses and individuals alike conduct their 
    transactions – through a more safe, transparent and effective ways, using cryptocurrencies.`
  },
  roadMapTitle: 'AEM ROAD MAP',
  futureMission: {
    title: 'Our Next Steps',
    desc: [
      `Our vision for AEM is to pioneer a movement that transforms the idea of 
      how both businesses and individuals alike conduct their transactions with cryptocurrency — through more safe, 
      transparent and effective ways.​`,
      `We intend to make a global impact, redefining the way 
      businesses approach crypto accounting as we know it. As blockchain technology continues to evolve, 
      so do we. We’re here to build the future one step at a time, helping crypto become a new norm.`,
    ],
  },
  roadMap :[
    [['1Q', 'Block Engine Startup Program'],
    ['2Q', 'Journaler PcC Mac App Xem/AUD Converter'],
    ['3Q', 'Journaler MVP Cloud + Testing'],
    ['4Q', 'USD and JPV Conversion Shortlisted by NEM Ventures']],
     [['1Q', 'Journaler MVP Code Improvement'],
    ['3Q', 'NEM2.0 XYM Catapult Testnet Integration AEM+ Multi Cryptocurrency Wallet Release RMIT Partnership'],
    ['4Q', 'USD and JPV Conversion Shortlisted by NEM Ventures']],
     [['1Q', 'AEM Pay Cryptocurrency Wallet App & RMIT Partnership'],
    ['2Q', 'AEM+ Code Improvement BTC MVP UX Upgrade'],
    ['3Q', 'Xero Accounting System API Integration'],
    ['4Q', 'AEM+ Cryptocurrency Swap Framework']],
    [['1Q', 'AEM Pay Cryptocurrency Wallet App & RMIT Partnership'],
    ['2Q', 'AEM+ Code Improvement BTC MVP UX Upgrade'],
    ['3Q', 'Xero Accounting System API Integration'],
    ['4Q', 'AEM+ Cryptocurrency Swap Framework']],
    [['1Q', 'XYM Symbol Mainnet Integration Journaler and AEM+ Monetisation Ethereum and ERC20 Token Integration Startup Bootcamp Xero Marketplace Launch'],
    ['2Q', 'Journaler XYM Live Integration Journaler ETH Live Integration Journaler Xero Marketplace Launch AEM Delta DeFi Wallet MVP'],
    ['3Q', 'Journaler Exchange Wallets Integration'],
    ['4Q', 'QuickBooks, Tax journals & Multisig Integration']],
     [['1Q', 'Journaler ERC20 Live Integration Journaler Wallet Balance Journal'],
    ['2Q', 'AEM+ Invoicing Wallet AEM+ Ethereum & NFT Integration Journaler Tax Journals'],
    ['3Q', 'Journaler Multi-User Access Journaler Polygon, Binance + More Live Integrations Journaler QuickBooks Integration Journaler Coinbase Integration'],
    ['4Q', 'Journaler Tax Reporting Dashboard']],
  ]


};




export default aboutUsContents
